<template>
  <div class="container">
    <div class="container-body">
        <div :class="ClientWidth_number > 850 ?'body-box' : 'body-box-phone'">
        <el-row>
            <el-col :span="6">
                <div class="grid-content bg-purple title" style="height:70px;width:128px;">
                    <h3>资产管理</h3>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple content">
                    <p>赛尔安全资产管理是一套互联网资产测绘和风险评估系统，采用网络全栈爬虫、高精度设备指纹识别、自适应智能调度算法、高危POC（Proof of Concept）漏洞自动化验证等关键技术，对网络空间资产进行四层建模（硬件设备层、操作系统层、服务支撑层、应用场景层），对资产高危漏洞进行专扫，帮助用户完成互联网暴露面资产摸底排查、高危风险识别、网络空间测绘，为网络安全态势感知、研判、处置提供基础支撑。是一套互联网资产测绘和风险评估系统，采用网络全栈爬虫、高精度设备指纹识别、自适应智能调度算法、高危POC（Proof of Concept）漏洞自动化验证等关键技术，对网络空间资产进行四层建模（硬件设备层、操作系统层、服务支撑层、应用场景层），对资产高危漏洞进行专扫，帮助用户完成互联网暴露面资产摸底排查、高危风险识别、网络空间测绘，为网络安全态势感知、研判、处置提供基础支撑。</p>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple content" style="width:50vw;margin:0 auto;">
                   <img src="../../assets/image_20190321152734_740.png" alt="" style="width:100%;height:100%;">
                </div>
            </el-col>
        </el-row>
          <el-row>
            <el-col :span="24">
                <div class="grid-content bg-purple content" style="margin-bottom:2vw;;width:50vw;margin:0 auto;">
                   <img src="../../assets/image_20190321152804_443.png" alt="" style="width:100%;height:100%;">
                </div>
            </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ClientWidth_number: document.documentElement.clientWidth,
    };
  },
  created() {
    this.getClientWidth();
   
  },
  methods: {
    getClientWidth() {
      window.onresize = () => {
        this.ClientWidth_number = document.documentElement.clientWidth;
      };
    },
  },
};
</script>

<style lang='less' scoped>
@baseWidth: 1980;
.pxfix(@w) {
  width: (@w / @baseWidth) * 100vw;
}
 .el-row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .container-body {
    .pxfix(1980);
    min-height: 700px;
    margin: 0 auto;
    background: #eff3f8;
    // margin-top: 20px;
    display: flex;
    flex: 1;
    .title{
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        font-weight: normal;
        font-family: "microsoft yahei";
        color: #555;
    }
      .content{
      margin: 0 1rem;
      color: #555;
      font-size: 0.8rem;;  
      line-height: 25px;  
       }
    .body-box{
     .pxfix(1040);
     margin:1.5rem auto;
    //  flex:1;
    padding: 0 3vw;
    background: #fff;
    }
    .body-box-phone{
        background: #fff;
        margin:1.5rem auto;
    }
     .body-box:hover{
     box-shadow: 0 0  10px #888888;
    }
  }
  .footer {
    line-height: 50px;
    background: #2f3034;
    width: 100%;
    font-size: 12px;
    color: #fff;
    text-align: center;
  }
</style>
